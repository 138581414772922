
import { Navbar, Nav,Container } from 'react-bootstrap';
import './App.css';
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import Events from './Components/Events';

function App() {
  
  return (
    <div>
      <Navbar bg="dark" variant="dark">
          <Container>
          <Navbar.Brand href="/events">WS Data</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/events">Events</Nav.Link>
            <Nav.Link href="/">Features</Nav.Link>
            <Nav.Link href="/">Pricing</Nav.Link>
          </Nav>
          </Container>
        </Navbar>
        <BrowserRouter>
        <Switch>
          <Route exact path="/events">
            <Events />
            <div>TEST</div>
          </Route>
          </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
